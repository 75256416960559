import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { WelcomeAutomaticEmailsTemplateStoreEffects } from './effects';
import { welcomeAutomaticEmailsTemplateReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'welcomeAutomaticEmailsTemplate',
      welcomeAutomaticEmailsTemplateReducer,
    ),
    EffectsModule.forFeature([WelcomeAutomaticEmailsTemplateStoreEffects]),
  ],
})
export class WelcomeAutomaticEmailsTemplateStoreModule {}
