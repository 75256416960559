import { createFeatureSelector, createSelector } from '@ngrx/store';

import { WelcomeAutomaticEmailsTemplate } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const selectState = createFeatureSelector<State>(
  'welcomeAutomaticEmailsTemplate',
);

export const selectAllItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectAllItemsWithoutWelcomeEmail = createSelector(
  selectAllItems,
  (templates: WelcomeAutomaticEmailsTemplate[]) =>
    templates.filter(({ id }) => id !== 1),
);

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);
